/* Base styles for all viewports */
@import url('https://fonts.googleapis.com/css2?family=Aladin&display=swap');
#prayerTimes {
  font-family: Arial, sans-serif;
  font-weight: 500;
  text-align: center;
  padding: 20px;
  display: grid;
  gap: 20px;

}
h2{
  font-family: "Aladin", system-ui;

  letter-spacing: 2px;
}
h3{
  font-family: "Aladin", system-ui;
  letter-spacing: 2px;

}
p{
  font-weight: 500;
}
#title{
  color:#CD9A50 ;
}
#dateElement {
  font-size: 24px;
  margin-bottom: 20px;
}

#currentTimeElement {
  font-size: 24px;
  margin-top: 10px;
  color: #333;
}
.prayer-info div {
  border: 3px solid #223881;
  border-radius: 124px;
  height: 144px;
  width: 150px;
}
.prayer-item {
  height: 205px !important;
  
}
.ramadan_wrapper{
  display: flex;
  justify-content: center;
  border: 3px solid #223881;
  border-radius: 50px;
  height: 144px;
  background-color: #CD9A50;
text-transform: uppercase;
}
.ramadan_wrapper p{
  color: black;
}
.ramadan_wrapper h3{
  color: black;
}
.ramadanItems{
  margin: 10px;
}
/* Styles for mobile view */
@media screen and (max-width: 767px) {
  #prayerTimes {
    background-color: #e8d8c9;
  }

  #dateElement {
    font-size: 20px;
  }

  .prayer-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .prayer-item {
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
  }

  .prayer-item h3 {
    font-size: 20px;
  }

  .prayer-item p {
    font-size: 16px;
  }

  /* Add styles for the highlighted prayers */
  .prayer-item.highlighted-yellow,
  .prayer-item.highlighted-red {
    background-color: #ffff00;
    border: 2px solid #ffa500;
    color: #333;
  }
}



/* Styles for desktop screens */
@media screen and (min-width: 768px) {
  #prayerTimes {
    background-color: #e8d8c9;
    padding: 30px;
    grid-template-columns: repeat(3, 1fr);
  }

  #dateElement {
    font-size: 28px;
  }

  .prayer-info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px; /* Adjust the gap according to your preference */
  }

  .prayer-item {
    padding: 15px;
    margin-bottom: 15px;
  }

  .prayer-item h3 {
    font-size: 24px;
  }

  .prayer-item p {
    font-size: 20px;
  }
  /* Add additional styles for desktop if needed */
}
